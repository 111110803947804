// src/Library.js

import React, { useEffect, useState } from 'react';
import { firestore } from './firebase';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Rating,
} from '@mui/material';

const Library = ({ user }) => {
  const [stories, setStories] = useState([]);
  const navigate = useNavigate();
  const [subgenres, setSubgenres] = useState({});

  useEffect(() => {
    const fetchStories = async () => {
      try {
        // Fetch all stories
        const storiesSnapshot = await firestore.collection('stories').get();
        const storiesData = storiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setStories(storiesData);
      } catch (error) {
        console.error('Error fetching stories:', error);
      }
    };

    fetchStories();
  }, []);

  // Group stories by genre and subgenre
  const storiesByGenreAndSubgenre = stories.reduce((acc, story) => {
    const genreName = story.genre.name || "Unknown Genre";
    const subgenreName = story.subgenre.name || "Unknown Subgenre";

    if (!acc[genreName]) {
      acc[genreName] = {};
    }
    if (!acc[genreName][subgenreName]) {
      acc[genreName][subgenreName] = [];
    }
    acc[genreName][subgenreName].push(story);
    return acc;
  }, {});

  // Function to handle story click
  const handleStoryClick = (storyId) => {
    navigate(`/story/${storyId}`); // Navigate to the story detail page
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Your Library
      </Typography>
      {Object.keys(storiesByGenreAndSubgenre).map((genre) => (
        <Box key={genre} mt={4}>
          <Typography variant="h5" gutterBottom>
            {genre}
          </Typography>
          {Object.keys(storiesByGenreAndSubgenre[genre]).map((subgenre) => (
            <Box key={subgenre} mt={2}>
              <Typography variant="h6" gutterBottom>
                {subgenre}
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(3, 1fr)", // 3 columns on extra-small screens
                    sm: "repeat(4, 1fr)", // 4 columns on small screens
                    md: "repeat(5, 1fr)", // 5 columns on medium screens
                    lg: "repeat(8, 1fr)", // 8 columns on large screens
                    xl: "repeat(8, 1fr)", // 8 columns on extra-large screens
                  },
                }}
              >
                {storiesByGenreAndSubgenre[genre][subgenre].map((story) => (
                  <Card key={story.id}>
                    <CardActionArea onClick={() => handleStoryClick(story.id)}>
                      <CardMedia
                        component="img"
                        sx={{ height: 100 }}
                        image={
                          story.coverImage ||
                          subgenres[story.subgenre.id]?.imageUrl ||
                          "/placeholder.jpg"
                        }
                        alt={story.title || "Story Cover"}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {story.title || "Untitled Story"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {story.isCompleted ? "Completed" : "In Progress"}
                        </Typography>
                        {story.rating && (
                          <Box display="flex" alignItems="center">
                            <Rating
                              name="read-only"
                              value={story.rating}
                              readOnly
                              size="small"
                            />
                          </Box>
                        )}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default Library;
